<template>
  <div class="pay-ali">
    <div class="pay-ali-title">
      <p class="pay-ali-title-text">支付宝支付列表</p>
      <div class="pay-ali-title-container">
        <el-button size="mini" type="primary" plain @click="$router.push({name: 'collection.pay.quickPay.ali.config'})">新增支付宝支付
        </el-button>
      </div>

      <div class="pay-ali-title-filter">
        <p>过滤条件:</p>
        <el-input v-model="pagination.name" placeholder="请输入支付名称" @change="load" size="mini"/>
      </div>
    </div>

    <div class="pay-ali-contain">
      <el-table :data="pay"
                max-height="420px">
        <el-table-column label="支付编号" align="center" prop="uuid" width="240px"></el-table-column>
        <el-table-column label="支付名称" align="center" prop="name"></el-table-column>
        <el-table-column label="自动续签时间" align="center">
          <template slot-scope="scope">
            <span>{{new Date(scope.row.expiresIn * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="签约时间" align="center">
          <template slot-scope="scope">
            <span>{{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="240px">
          <template slot-scope="scope">
            <span class="edit" @click="handleTestPay(scope.row)">测试支付</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>

    <c-dialog :visible.sync="testVisible" :title="'测试支付宝'" :width="'30rem'" :height="'24rem'">

      <div style="text-align: center">
        <qrcode :value="payUrl" :options="{ width: 200 }"></qrcode>
        <p>请用【支付宝】扫描上方二维码，测试支付</p>
        <p class="delete">(支付成功不会产生订单)</p>
      </div>
    </c-dialog>


    <c-dialog :visible.sync="bindVisible" :width="'42rem'" :height="'36rem'">
      <div slot="title" class="input-title">
        <p class="input-title-text">支付宝支付列表</p>
        <div class="input-title-container">
          <el-button size="mini" type="primary" plain @click="handleBindAli">关联支付宝支付</el-button>
        </div>

        <div class="input-title-filter">
          <p>过滤条件:</p>
          <el-input v-model="bind.name"  clearable placeholder="请输入设备名称" @change="inputChange" size="mini"/>
        </div>

      </div>

      <devices ref="devices"></devices>
    </c-dialog>

  </div>

</template>

<script>

  import {payAliTest, payAliList, payAliUnion, quickPayBindDrCoffee, payAliDelete} from "@/api/collection/pay";
  import {checkObj, fillObj} from "@/util/checkLogin";
  import CDialog from '@/components/Dialog'
  import Devices from '@/components/Device'
  import {payBindDevice} from "@/api/collection/pay";

  export default {
    name: '',
    components: {
      CDialog,
      Devices
    },
    mounted: function () {
      this.load('')
    },
    data: function () {
      return {
        pay: [],
        testVisible: false,
        editVisible: false,
        showInfo: false,
        bindVisible: false,
        payUrl: `系统故障，发起支付失败`,
        cert: {
          secret: '',
          key: '',
          pem: '',
        },
        aliForm: {
          name: '',
          appId: '',
          publicKey: '',
          privateKey: '',
        },
        mAliForm: {
          uuid: '',
          name: '',
          appId: '',
          publicKey: '',
          privateKey: '',
        },
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
          name: ''
        },
        bind: {
          uuid: '',
          name: ''
        }
      }
    },
    methods: {
      load(name) {
        this.pagination.name = name
        payAliUnion(this.pagination).then(res => {
          this.pay = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },

      showSecret(data) {
        this.cert = {
          key: data.publicKey,
          pem: data.privateKey,
        }
        this.showInfo = true
      },

      handleUpdateName(pay) {
        let name = pay.name
        this.$prompt('请输入需要修改名称', '修改名称', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: name,
        }).then(({value}) => {
          if (pay.name !== value) {
            quickPayBindDrCoffee({uuid: pay.uuid, name: value}).then(res => {
              this.$message.success('更新成功')
              pay.name = value
            })
          }
        }).catch(() => {
        });
      },

      handleDeviceShow(data) {
        this.bindVisible = true
        this.bind.uuid = data.uuid
        this.$nextTick(function () {
          this.$refs.devices.loadDevices(this.bind.name)
        })
      },

      inputChange(val) {
        this.$refs.devices.loadDevices(val)
      },

      handleTestPay(data) {
        payAliTest({price: `1.00`, uuid: data.uuid}).then(res => {
          this.payUrl = res.data
          this.testVisible = true
        })

      },
      // handleEditPay(data) {
      //   fillObj(this.mAliForm, data)
      //   this.editVisible = true
      // },
      //
      // handleCreateAli() {
      //   let result = checkObj(this.aliForm, [
      //     {value: "name", message: "支付名称"},
      //     {value: "appId", message: "应用ID"},
      //     {value: "publicKey", message: "支付宝公钥"},
      //     {value: "privateKey", message: "商户私钥"},
      //   ])
      //
      //   if (!result.status) {
      //     this.$message.warning(result.message + `不能为空`)
      //     return
      //   }
      //   payAliCreate(this.aliForm).then(res => {
      //     this.createVisible = false
      //     this.load()
      //     this.aliForm = {
      //       name: '',
      //       appId: '',
      //       mchId: '',
      //       mchSecret: '',
      //       certKey: '',
      //       certPem: '',
      //     }
      //   })
      // },
      //
      // handleUpdateAli() {
      //   let result = checkObj(this.mAliForm, [
      //     {value: "name", message: "支付名称"},
      //     {value: "appId", message: "应用ID"},
      //     {value: "publicKey", message: "支付宝公钥"},
      //     {value: "privateKey", message: "商户私钥"},
      //   ])
      //
      //   if (!result.status) {
      //     this.$message.warning(result.message + `不能为空`)
      //     return
      //   }
      //
      //   payAliUpdate(this.mAliForm).then(res => {
      //     this.editVisible = false
      //     this.handleCurrentChange()
      //   })
      // },
      handleCurrentChange(value) {
        if (value !== undefined) {
          this.pagination.curPage = value
          this.pagination.start = (value - 1) * this.pagination.limit
        }
        payAliList(this.pagination).then(res => {
          this.pay = res.data || []
        })
      },

      handleBindAli() {
        let deviceUuid = this.$refs.devices.getDevices() || []
        if (deviceUuid.length === 0) {
          this.$message.warning("请先选取需要绑定的设备")
          return
        }
        payBindDevice({uuid: this.bind.uuid, deviceUuid: deviceUuid, isAli: true}).then(res => {
          this.bindVisible = false
          this.$message.success('绑定成功')
        })
      },

      handleDelete(row) {
        this.$confirm('此操作将删除支付, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          payAliDelete({uuid: row.uuid}).then(() => {
            this.$message.success('删除成功')
            this.load()
          })
        }).catch(() => {

        })
      },
    }
  }
</script>

<style lang="scss">
  .pay-ali {
    &-title {
      display: flex;
      padding: $padding 0;
      align-content: center;

      &-text {
        font-weight: 500;
        margin-right: $padding;
      }

      &-container {
        flex: 1;
      }

      &-filter {
        display: flex;
        align-content: center;

        p {
          width: 120px;
          font-size: 12px;
          margin-right: $padding;
          text-align: right;
        }
      }


    }

    .input-title {
      display: flex;
      padding: $padding 0;
      align-content: center;

      &-text {
        font-weight: 500;
        margin-right: $padding;
      }

      &-container {
        flex: 1;
        text-align: left;
      }

      &-filter {
        //flex: 1;
        display: flex;
        text-align: right;
        align-content: center;
        margin-right: $padding;

        p {
          width: 120px;
          font-size: 12px;
          margin-right: $padding;
          text-align: right;
        }
      }
    }

    .edit {
      margin-right: $padding-8;
      color: $btn_color;
      cursor: pointer;
    }

    .delete {
      color: $btn_sec_color;
      margin-right: $padding;
      cursor: pointer;
    }

    .look {
      color: $_theme_color;
      cursor: pointer;
    }


    .wx-form {
      padding: $padding;
    }
  }
</style>
